import React, { createContext, useState, useEffect } from "react";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [games, setGames] = useState([]);
  const [categories, setCategories] = useState([]);
  const [videos, setVideos] = useState([]);
  const [externalFeatured, setExternalFeatured] = useState([]);

  useEffect(() => {
    fetch("data/games.json")
      .then((response) => response.json())
      .then((data) => setGames(data));

    fetch("data/categories.json")
      .then((response) => response.json())
      .then((data) => setCategories(data));

    fetch("data/videos.json")
      .then((response) => response.json())
      .then((data) => setVideos(data));

    fetch("data/featured-external.json")
      .then((response) => response.json())
      .then((data) => setExternalFeatured(data));
  }, []);

  return <DataContext.Provider value={{ games, categories, videos, externalFeatured }}>{children}</DataContext.Provider>;
};
