import AllGamesFilters from "./AllGamesFilters";
import GamesFooter from "./ui/all-games/GamesFooter";
import GamesList from "./ui/all-games/GamesList";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../providers/DataContext";

const AllGames = () => {
  const { games: library, categories } = useContext(DataContext);

  const [selectedAge, setSelectedAge] = useState(-1);
  const [selectedSubject, setSelectedSubject] = useState(-1);
  const [displayedGames, setDisplayedGames] = useState(library);
  const [disabledCategories, setDisabledCategories] = useState([]);

  useEffect(() => setDisplayedGames(library), [library]);

  const onFilterChange = (filterType, filterId) => {
    // If id already in list, remove it
    switch (filterType) {
      case "ages":
        if (selectedAge == filterId) {
          setSelectedAge(-1);
        } else {
          setSelectedAge(filterId);
        }
        break;
      case "subjects":
        if (selectedSubject == filterId) {
          setSelectedSubject(-1);
        } else {
          setSelectedSubject(filterId);
        }
        break;
    }
  };

  // Update displayed games when a filter is selected
  useEffect(() => {
    // Only do filtering if there is a filter selected
    if (selectedAge != -1 || selectedSubject != -1) {
      let filteredGames = [...library];

      // Filter Ages
      if (selectedAge != -1) {
        const selectedAgeMinIdx = categories.ages.findIndex((cat) => cat.id == parseInt(selectedAge));
        const selectedAgeMin = categories.ages[selectedAgeMinIdx];

        filteredGames = filteredGames.filter((game) => {
          const targetCatIdx = categories.ages.findIndex((cat) => cat.id == game.categories.ageId);
          if (targetCatIdx == -1) {
            console.error("Game has age index with no matching id ");
          }

          // Lowest game age number
          const gameMinAge = categories.ages[targetCatIdx].minimumAge;
          return gameMinAge >= selectedAgeMin.minimumAge;
        });
      }

      // Filter Subjects
      if (selectedSubject != -1) {
        filteredGames = filteredGames.filter((game) => {
          let allGameCategories = [];
          allGameCategories = allGameCategories.concat(game.categories.ageId, ...game.categories.subject);
          return allGameCategories.includes(selectedSubject);
        });
      }

      setDisplayedGames(filteredGames);
    } else {
      // Reset to full list
      setDisplayedGames(library);
    }
  }, [selectedAge, selectedSubject]);

  // Update disabled categories when the displayed games change
  useEffect(() => {
    // Disabled categories are ones that of the games being displayed, none have in their aggregate categories
    let updatedDisabled = [];

    // Note: only disable categories from the opposing filter type
    if (selectedAge != -1) {
      // collect disabled subjects
      const presentSubjects = [];
      displayedGames.forEach((game) => presentSubjects.push(...game.categories.subject));
      updatedDisabled.push(...categories.subjects.filter((cat) => !presentSubjects.includes(cat.id)));
    }

    // and reverse
    if (selectedSubject != -1) {
      // collect disabled ages
      const presentAges = [];
      displayedGames.forEach((game) => presentAges.push(game.categories.ageId));

      updatedDisabled.push(...categories.ages.filter((cat) => !presentAges.includes(cat.id)));
    }

    updatedDisabled = updatedDisabled.map((cat) => cat.id);

    setDisabledCategories(updatedDisabled);
  }, [displayedGames]);

  return (
    <div className="bg-[#FFC907] flex flex-col rounded-xl">
      <div className="px-6">
        <AllGamesFilters onFilterChange={onFilterChange} disabledCategories={disabledCategories} selectedAge={selectedAge} selectedSubject={selectedSubject} />
      </div>
      <GamesList games={displayedGames} />
      <GamesFooter />
    </div>
  );
};

export default AllGames;
