import { useContext } from "react";
import FiltersRow from "./ui/all-games/FiltersRow";
import { DataContext } from "../providers/DataContext";

const AllGamesFilters = ({ onFilterChange, disabledCategories, selectedAge, selectedSubject }) => {
  const { categories: categoryData } = useContext(DataContext);

  const onChildFilterChange = (categoryName, filterId) => {
    onFilterChange(categoryName, filterId);
  };

  return (
    <div className="flex flex-col gap-1 md:gap-2 py-4">
      <FiltersRow categoryName={"ages"} categoriesList={categoryData.ages} themeColor="#009AD7" filtersUpdateHandler={onChildFilterChange} disabledCategories={disabledCategories} selectedId={selectedAge} />
      <FiltersRow categoryName={"subjects"} categoriesList={categoryData.subjects} themeColor="#17345A" filtersUpdateHandler={onChildFilterChange} disabledCategories={disabledCategories} selectedId={selectedSubject} />
    </div>
  );
};

export default AllGamesFilters;
