import VideosList from "./ui/videos/VideosList";
import { useContext } from "react";
import { DataContext } from "../providers/DataContext";

const RelatedVideos = () => {
  const { videos: videosData } = useContext(DataContext);

  return (
    <div className="w-full bg-[#652FDD] rounded-2xl flex flex-col items-center overflow-hidden">
      <div className="text-white font-bold py-3 text-xl md:text-2xl font-nunitoEB">Game-Related Videos</div>
      <VideosList videos={videosData} />
    </div>
  );
};

export default RelatedVideos;
