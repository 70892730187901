import NavLink from "./ui/topbar/NavLink";

const Topbar = () => {
  return (
    <div className="top-0 px-2 md:px-6 bg-[#17345A] h-10 md:h-14 items-center relative flex gap-4 md:gap-12">
      <div
        className="w-[70px] md:w-[120px] hover:cursor-pointer"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            window.open("https://www.gpb.org/education", "_blank");
          }
        }}
        onClick={() => window.open("https://www.gpb.org/education", "_blank")}
      >
        <img className="object-cover h-full w-full" src="logo_GPB.png" alt="GPB Education Home" />
      </div>
      <div className="flex gap-4 md:justify-normal md:gap-8 items-center w-full">
        <NavLink textName="Games" urlLink={"."} />
        <NavLink textName="Support Materials" urlLink={"https://www.gpb.org/education/games/support-materials"} />
        <NavLink textName="Kids & Family" urlLink={"https://www.gpb.org/kids-and-family"} />
      </div>
    </div>
  );
};

export default Topbar;
