import Footer from "./Footer";
import HomeContent from "./HomeContent";
import Topbar from "./Topbar";
import ScrollToTop from "./ui/ScrollToTop";
import { DataProvider } from "../providers/DataContext";

const Home = () => {
  return (
    <div className="h-full">
      <DataProvider>
        <Topbar />
        <div className="mx-6 mt-2">
          <HomeContent />
          <div className="sticky bottom-4 pb-2 z-50 flex justify-end w-full">
            <ScrollToTop className="" />
          </div>
        </div>
        <Footer />
      </DataProvider>
    </div>
  );
};

export default Home;
